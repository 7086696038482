import { Amap, config as AmapConfig, Marker } from '@amap/amap-react';
import { Form, Input, InputNumber } from 'antd';
import { useEffect, useRef, useState } from 'react';

import './index.less';
import MARKER_SVG from './marker.svg';

const FormItem = Form.Item;
AmapConfig.key = '8faf092bfa96e5b6748ea7e0a2d6ac9c';

export const ExampleMap = (props: any) => {
  const { activeAddress } = props;
  const mapRef = useRef(null);
  const [center, setCenter] = useState([116.473571, 39.993083]);
  const [zoom, setZoom] = useState<any>(15);
  const [pitch, setPitch] = useState<any>(45);
  const [rotation, setRotation] = useState<any>(15);

  useEffect(() => {
    if (activeAddress && activeAddress.longitude && activeAddress.latitude) {
      setCenter([Number(activeAddress.latitude), Number(activeAddress.longitude)]);
    }
    return () => {
      mapRef.current = null;
    };
  }, [activeAddress]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* <Form layout='inline'>
        <FormItem label='zoom'>
          <InputNumber value={zoom} onChange={(v) => setZoom(v)} style={{ width: '60px' }} />
        </FormItem>
        <FormItem label='center'>
          <Input readOnly value={center.join(',')} style={{ width: '180px' }} />
        </FormItem>
        <FormItem label='pitch'>
          <InputNumber value={pitch} onChange={(v) => setPitch(v)} step={5} style={{ width: '60px' }} />
        </FormItem>
        <FormItem label='rotation'>
          <InputNumber value={rotation} onChange={(v) => setRotation(v)} step={5} style={{ width: '60px' }} />
        </FormItem>
      </Form> */}

      <div style={{ width: '100%', height: '100%' }}>
        <Amap
          ref={mapRef}
          mapStyle='amap://styles/whitesmoke'
          zoom={zoom}
          center={center}
          pitch={pitch}
          rotation={rotation}
          showIndoorMap={false}
          isHotspot
          onHotspotClick={(map, e) => {
            if (e && e.lnglat) {
              setCenter([e.lnglat.lng, e.lnglat.lat]);
            }
          }}
          onZoomChange={(map) => setZoom(map.getZoom())}
          // onMapMove={(map) => setCenter(map.getCenter().toArray())}
          onDragging={(map) => {
            const p = map.getPitch();
            setPitch(p);

            const r = map.getRotation();
            setRotation(r);
          }}
        >
          <Marker
            position={center}
            label={{
              content: activeAddress.name,
              direction: 'top-center',
            }}
          >
            <img src={MARKER_SVG} alt='marker' />
            {/* <div className='custom-marker'>{activeAddress.name}</div> */}
          </Marker>
        </Amap>
      </div>
    </div>
  );
};
